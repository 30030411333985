import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ListTwoCol = ({ items }) => {
  const listDivided = divideList(items)

  return (
    <div className="md:grid grid-cols-2">
      <ul>
        {listDivided[0].map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <ul>
        {listDivided[1].map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

function divideList(list) {
  const middleIndex = Math.ceil(list.length / 2);

  return [
    list.slice(0, middleIndex),
    list.slice(-middleIndex)
  ]
}

ListTwoCol.propTypes = {
  items: PropTypes.array.isRequired
}

export default ListTwoCol

