import React from 'react'

const DividerHeading = ({children}) => {
  return (
    <h4 className="flex items-center text-gray-600 my-8">
      {children}
      <hr className="ml-6 flex-grow"></hr>
    </h4>
  )
}

export default DividerHeading


