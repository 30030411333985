import React from 'react'
import PropTypes from 'prop-types'

const ResumeExperienceItem = ({title, date, location, className, children}) => {
  return (
    <div className={ `relative ${className}` }>
      <div className="md:flex md:mb-5">
        <h3 className="flex-grow">{title}</h3>

        <div className="text-secondary flex md:block mt-5 md:-mt-1">
          <span className="md:absolute md:leading-7 top-0 left-0 md:-ml-28 md:pt-1">
            {date}
          </span>

          <span className="px-2 md:hidden">|</span>

          <span>{location}</span>
        </div>
      </div>

      {children}
    </div>
  )
}

ResumeExperienceItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  location: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default ResumeExperienceItem
